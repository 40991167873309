<template>
  <div>
    <BasicLogo width="300" />
    <div class="content-title">
      <SeparatorTitle title="Olvidó su contaseña" :separator="false" />
    </div>
    <BasicSubtitle
      title="Ingrese el email asociado a su cuenta."
      subtitle="Le será enviado un mail de confirmación para que pueda restablecerla."
    />
    <div class="content-form">
      <BasicInput v-model="email" label="ejemplo@tuappto.com" />
      <FormError :show="emailError" message="Ingrese un email válido" />
    </div>
    <div class="content-submit">
      <PrimaryButton label="Enviar" :click="onForgot" />
    </div>
    <BasicSubtitle
      subtitle="Si no encuentra nuestro email, revise su casilla de span o vuelva a intentar reenviarlo en unos minutos."
    />
  </div>
</template>

<script>
import BasicLogo from "../../widgets/logo/BasicLogo";
import BasicInput from "../../widgets/input/BasicInput.vue";
import PrimaryButton from "../../widgets/button/PrimaryButton.vue";
import SeparatorTitle from "../../widgets/title/SeparatorTitle.vue";
import FormError from "../../widgets/error/FormError.vue";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle.vue";
import { useRouter } from "vue-router";
import { ref, watchEffect } from "vue";
import store from "../../store";
import { validation } from "../../constants";

export default {
  components: {
    BasicLogo,
    BasicInput,
    PrimaryButton,
    SeparatorTitle,
    FormError,
    BasicSubtitle,
  },
  setup() {
    const router = useRouter();
    const email = ref("");
    const emailError = ref(false);

    watchEffect(() => {
      if (email.value && validation.emailRegex.test(email.value))
        emailError.value = false;
    });

    const validate = () => {
      let valid = true;

      if (!email.value || !validation.emailRegex.test(email.value)) {
        emailError.value = true;
        valid = false;
      }

      return valid;
    };

    const onForgot = async () => {
      if (!validate()) return;
      store.commit("setLoading", true);
      const response = await store.dispatch("AUTH_FORGOT", {
        email: email.value,
      });
      store.commit("setLoading", false);
      if (response.ok) {
        store.state.toast.add({
          severity: "success",
          summary: "",
          detail: response.message,
          life: 5000,
        });
        router.push("/validate");
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
    };

    return { email, emailError, onForgot };
  },
};
</script>

<style scoped>
.content-title {
  margin-top: 4rem;
}
.content-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
}
.content-submit {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}
</style>
